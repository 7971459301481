export default Object.freeze({
	general: {
		sku: '',
		alternate_sku: '',
		is_service: 'false',
		internal_use: 'false',
		subcategory: '',
		label: '',
		sat_information: {
			description: '',
			sat_key: '',
		},
		measurement_unit_id: '1',
		minimum_threshold: '',
		maximum_threshold: '',
		unit_cost: '',
		prices: {
			percent: '',
			fixed_price: '',
			sale_price: '',
			vat: '',
		},
		in_s2r_shop: false,
		in_mercadolibre_shop: false,
		stock: 1,
		wc_price: 0,
		supplier_id: '',
		subarea: 'refacciones',
	},
})
