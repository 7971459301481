<template>
	<div>
		<a-spin :tip="spinnerLoaderLabel" size="large" :spinning="spinnerLoader">
			<div class="row pt20">
				<div class="col-md-3">
					<b>Rango de Fecha <small>(Registro)</small></b>
					<a-range-picker style="width: 100%" @change="initModule" v-model="filters.defaultDate" :default-value="filters.defaultDate" :format="dateFormat" />
				</div>
				<div class="col-md-3">
					<b>Proveedor</b>
					<a-select show-search allow-clear option-filter-prop="children" :filter-option="filterOption" v-model="filters.supplier_id" style="width: 100%" @change="initModule">
						<a-select-option v-for="(supplier, index) in suppliersList" :key="index" :value="supplier.id">{{ supplier.tradename.toUpperCase() }}</a-select-option>
					</a-select>
					<small @click="getSuppliersList" class="pointer">Recarga</small>
				</div>
				<div class="col-md-3">
					<b>Estatus</b>
					<a-select v-model="filters.status" style="width: 100%" @change="initModule" allowClear>
						<a-select-option value="">Todos</a-select-option>
						<a-select-option value="C">Cotización</a-select-option>
						<a-select-option value="0">Por autorizar</a-select-option>
						<a-select-option value="1">Por pagar</a-select-option>
						<a-select-option value="2">Pagadas</a-select-option>
						<a-select-option value="3">Facturadas</a-select-option>
						<a-select-option value="4">Validadas</a-select-option>
					</a-select>
				</div>
				<div class="col-md-3">
					<b>Cuenta de retiro</b>
					<a-select style="width: 100%" v-model="filters.destination" @change="initModule" allowClear>
						<a-select-option value="panel">Morado</a-select-option>
						<a-select-option value="pacific">Azul</a-select-option>
						<a-select-option value="rls">Gris</a-select-option>
					</a-select>
				</div>
				<div class="col-md-12 pt20 text-right">
					<downloadExcel class="btn btn-primary" :data="xlsxJsonData" :fields="xlsxJsonFields" worksheet="" :name="`reporte_ODC_${moment().format('DD-MM-YYYY')}.xls`">
						Descargar reporte
					</downloadExcel>
				</div>
			</div>
			<div class="row pt20">
				<div class="col-md-12">
					<a-table
						:columns="columns"
						:dataSource="purchaseOrdersList"
						:scroll="{ x: 1300 }"
						:rowClassName="
							(_record) => {
								if (_record.from_pacific) {
									return 'bgGreen'
								}
								if (_record.from_panel) {
									return 'bg-purple'
								}
								if (_record.from_rls) {
									return 'bgRls'
								}
							}
						"
					>
						<div slot="created_at" slot-scope="record">{{ moment(record.created_at).format('DD-MM-YYYY HH:mm') }} hrs.</div>
						<div slot="payment_date" slot-scope="record">
							{{ record.payment_date ? `${moment(record.payment_date).format('DD-MM-YYYY HH:mm')} hrs.` : '-' }}
						</div>
						<div slot="business_name" slot-scope="record" class="text-left">
							{{ record.business_name }}
						</div>
						<div slot="elements" slot-scope="record" class="text-left">
							<div v-for="(e, index) in record.elements" :key="index">{{ `${e.quantity}x ${e.description}` }}</div>
						</div>
						<div slot="expense_type" slot-scope="record" class="text-left">
							<div v-if="record.expense_type">
								{{ getcfdiUse(record.expense_type) }}
							</div>
						</div>
						<div slot="payment_method_data" slot-scope="record" class="text-left">
							{{ record.payment_method_data ? getPaymentMethod(record.payment_method_data) : '' }}
						</div>
						<div slot="subtotal" slot-scope="record" class="text-right">
							{{ numeral(getSubtotal(record.id)).format('$0,0.00') }}
						</div>
						<div slot="general_discount" slot-scope="record" class="text-right">
							{{ numeral(record.general_discount).format('$0,0.00') }}
						</div>
						<div slot="iva" slot-scope="record" class="text-right">
							{{ numeral(getIVA(record.id)).format('$0,0.00') }}
						</div>
						<div slot="ret_isr" slot-scope="record" class="text-right">
							{{ numeral(record.general_discount).format('$0,0.00') }}
						</div>
						<div slot="ret_iva" slot-scope="record" class="text-right">
							{{ numeral(record.general_discount).format('$0,0.00') }}
						</div>
						<div slot="total" slot-scope="record" class="text-right">
							{{ numeral(record.total).format('$0,0.00') }}
						</div>
						<div slot="status" slot-scope="record" class="text-right">
							{{ getODCStatus(record.status) }}
						</div>
						<div slot="branch_office_id" slot-scope="record" class="text-right">
							{{ branchOffice(record.branch_office_id)?.branch_office_name ?? '' }}
						</div>
					</a-table>
				</div>
			</div>
		</a-spin>
		<a-modal width="30%" :visible="orderModal" :footer="null" title="Detalle de pedido" :closable="true" @cancel="onCloseModal">
			<div v-for="(element, index) in elements" :key="index">
				{{ element.quantity }}x <span v-if="element.measurement_unit">{{ element.measurement_unit == 'liter' ? 'litro' : element.measurement_unit == 'piece' ? 'pieza' : 'servicio' }}</span> -
				{{ element.description }}
				<div v-if="element.service_order > 0">
					<a-tag color="purple">ODS {{ element.service_order }}</a-tag>
				</div>
				<div v-if="element.sku">
					<small>{{ element.sku }}</small>
				</div>
			</div>
		</a-modal>
	</div>
</template>

<script>
import { mapGetters } from 'vuex'
import moment from 'moment'
import numeral from 'numeral'
import { mask } from 'vue-the-mask'
import { VMoney } from 'v-money'
import utilities from '@/services/utilities'
import downloadExcel from 'vue-json-excel'
import { cfdiUseList } from '@/constants'

const dateFormat = 'DD-MM-YYYY'

export default {
	name: 'PurchaseOrdersReports',
	directives: {
		mask,
		money: VMoney,
	},
	components: {
		downloadExcel,
	},
	data() {
		return {
			columns: [
				{
					title: '# de ODC',
					fixed: 'left',
					dataIndex: 'id',
					align: 'center',
					width: 100,
				},
				{
					title: 'Fecha de creación',
					scopedSlots: { customRender: 'created_at' },
					align: 'center',
					width: 130,
				},
				{
					title: 'Fecha de pago',
					scopedSlots: { customRender: 'payment_date' },
					align: 'center',
					width: 130,
				},
				{
					title: 'RFC proveedor',
					dataIndex: 'rfc',
					align: 'center',
					width: 150,
				},
				{
					title: 'Razón Social',
					scopedSlots: { customRender: 'business_name' },
					align: 'center',
					width: 250,
				},
				{
					title: 'Concepto',
					scopedSlots: { customRender: 'elements' },
					align: 'center',
					width: 500,
				},
				{
					title: 'Tipo de gasto/pago',
					scopedSlots: { customRender: 'expense_type' },
					align: 'center',
					width: 150,
				},
				{
					title: 'Forma de pago',
					scopedSlots: { customRender: 'payment_method_data' },
					align: 'center',
					width: 150,
				},
				{
					title: 'Subtotal',
					scopedSlots: { customRender: 'subtotal' },
					align: 'center',
					width: 100,
				},
				{
					title: 'Descuento',
					scopedSlots: { customRender: 'general_discount' },
					align: 'center',
					width: 100,
				},
				{
					title: 'IVA acreditable',
					scopedSlots: { customRender: 'iva' },
					align: 'center',
					width: 100,
				},
				{
					title: 'Retención de ISR',
					scopedSlots: { customRender: 'ret_isr' },
					align: 'center',
					width: 100,
				},
				{
					title: 'Retención de IVA',
					scopedSlots: { customRender: 'ret_iva' },
					align: 'center',
					width: 100,
				},
				{
					title: 'Total',
					scopedSlots: { customRender: 'total' },
					align: 'center',
					width: 100,
				},
				{
					title: 'Estatus',
					scopedSlots: { customRender: 'status' },
					align: 'center',
					width: 100,
				},
				{
					title: 'Folio fiscal',
					scopedSlots: { customRender: 'folio_fiscal' },
					align: 'center',
					width: 200,
				},
				{
					title: 'Sucursal',
					scopedSlots: { customRender: 'branch_office_id' },
					align: 'center',
					width: 200,
				},
			],
			dateFormat,
			orderModal: false,
			elements: [],
			selectedRowKeys: [],
			modalTaxVisible: false,
			modalPayrollVisible: false,
			modalBankCommissionVisible: false,
			modalRefundVisible: false,
			money: {
				decimal: '.',
				thousands: ',',
				precision: 2,
			},
			xlsxJsonFields: {
				'# de ODC': 'id',
				'Fecha de creación': {
					field: 'created_at',
					callback: (value) => {
						return moment(value).format('DD/MM/YYYY')
					},
				},
				'Fecha de pago': {
					field: 'payment_date',
					callback: (value) => {
						if (value) {
							return moment(value).format('DD/MM/YYYY')
						}
						return ''
					},
				},
				'RFC proveedor': 'rfc',
				'Razón Social': 'business_name',
				Concepto: {
					field: 'elements',
					callback: (value) => {
						let label = ''
						value.forEach((e, index) => {
							label += `${e.quantity}x ${e.description}`
							if (index < value.length - 1) {
								label += `\n\t`
							}
						})
						return label
					},
				},
				'Tipo de gasto/pago': {
					field: 'expense_type',
					callback: (code) => {
						return this.getcfdiUse(code)
					},
				},
				'Forma de pago': {
					field: 'payment_method_data',
					callback: (value) => {
						if (value) {
							return this.getPaymentMethod(value)
						}
					},
				},
				Subtotal: {
					field: 'id',
					callback: (id) => {
						return this.getSubtotal(id)
					},
				},
				Descuento: {
					field: 'general_discount',
					callback: (value) => {
						return value || ''
					},
				},
				'IVA acreditable': {
					field: 'id',
					callback: (id) => {
						return this.getIVA(id)
					},
				},
				'Retención de ISR': 'name',
				'Retención de IVA': 'name',
				Total: 'total',
				Estatus: {
					field: 'status',
					callback: (value) => {
						return this.getODCStatus(value)
					},
				},
				'Folio fiscal': 'name',
				Sucursal: {
					field: 'branch_office_id',
					callback: (branch_office_id) => {
						return this.branchOffice(branch_office_id)?.branch_office_name ?? ''
					},
				},
			},
			cfdiUseList,
		}
	},
	computed: {
		...mapGetters('purchaseOrders', ['purchaseOrdersList', 'filters', 'totalPurchaseOrders', 'isCashODC']),
		...mapGetters('user', ['user', 'isUserAdmin']),
		...mapGetters('suppliers', ['suppliersList']),
		...mapGetters('branchOffices', ['branchOfficesList']),
		spinnerLoaderLabel() {
			return this.$store.state.purchaseOrders.spinnerLoaderLabel
		},
		spinnerLoader() {
			return this.$store.state.purchaseOrders.spinnerLoader
		},
		xlsxJsonData() {
			let localPurchaseOrdersList = this.purchaseOrdersList.filter((e) => !e.is_cash_odc)
			return localPurchaseOrdersList.length ? localPurchaseOrdersList : []
		},
	},
	mounted() {
		this.getSuppliersList()
		if (this.branchOfficesList.length == 0) {
			this.$store.dispatch('branchOffices/GET')
		}
	},
	methods: {
		numeral,
		moment,
		branchOffice(id) {
			return this.branchOfficesList.find((e) => e.id == id)
		},
		getIVA(id) {
			let theRecord = this.purchaseOrdersList.find((e) => e.id == id)
			if (utilities.objectValidate(theRecord, 'general_discount', false)) {
				if (theRecord.discount_type == 'after') {
					return numeral(((theRecord.total + theRecord.general_discount) / 1.16) * 0.16).format('0.00')
				} else {
					return numeral((theRecord.total / 1.16) * 0.16).format('0.00')
				}
			} else {
				return numeral((theRecord.total / 1.16) * 0.16).format('0.00')
			}
			return ''
		},
		getSubtotal(id) {
			let theRecord = this.purchaseOrdersList.find((e) => e.id == id)
			if (utilities.objectValidate(theRecord, 'general_discount', false)) {
				if (theRecord.discount_type == 'after') {
					return numeral((theRecord.total + theRecord.general_discount) / 1.16).format('0.00')
				} else {
					return numeral(theRecord.total / 1.16).format('0.00')
				}
			} else {
				return numeral(theRecord.total / 1.16).format('0.00')
			}
			return ''
		},
		getPaymentMethod(value) {
			if (value != '') {
				let paymentMethodData = JSON.parse(value)
				return numeral(paymentMethodData.payment_method).value() == 1 ? 'Efectivo' : 'Transferencia Bancaria'
			}
			return ''
		},
		getcfdiUse(code) {
			if (code) {
				return utilities.objectValidate(
					cfdiUseList.find((e) => e.key == code),
					'label',
					'',
				)
			}
			return ''
		},
		getODCStatus(value) {
			switch (value) {
				case 'C':
					return 'Cotización'
				case 0:
					return 'Por autorizar'
				case 1:
					return 'Por pagar'
				case 1.1:
					return 'Por pagar'
				case 2:
					return 'Pagadas'
				case 3:
					return 'Facturadas'
				case 4:
					return 'Validadas'
				default:
					return ''
					break
			}
		},
		filterOption(input, option) {
			return option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0
		},
		getSuppliersList() {
			this.$store.dispatch('suppliers/GET_NO_REDIRECT')
		},
		initModule() {
			//
			let payload = {
				status: this.filters.status,
				supplier_id: utilities.objectValidate(this.filters, 'supplier_id', false) ? this.filters.supplier_id : false,
				report: true,
				destination: utilities.objectValidate(this.filters, 'destination', false) ? this.filters.destination : false,
			}

			if (utilities.objectValidate(this.filters, 'defaultDate', false) && this.filters.defaultDate.length == 2) {
				payload = {
					...payload,
					startDate: moment(this.filters.defaultDate[0]).format('YYYY-MM-DD'),
					endDate: moment(this.filters.defaultDate[1]).format('YYYY-MM-DD'),
				}
			}

			this.$store.dispatch('purchaseOrders/GET', payload)
		},
		onOpenModal(elements) {
			this.elements = _.cloneDeep(elements)
			this.orderModal = true
		},
		onCloseModal() {
			this.elements = []
			this.orderModal = false
		},
	},
}
</script>

