export const purchaseOrders = {
	structure: {
		area_id: '',
		expense_type: '',
		priority: '1',
		beneficiary: '',
		supplier: '',
		advance_ticket: '',
		payment_method_index: '',
		payment_method_data: {},
		order: {
			supplier: '',
			quantity: 1,
			unit_of_measurement: '',
			description: '',
			unit_price: 0,
			link: '',
			definers: {
				unit_price: false,
				supplier: false,
			},
		},
		comments: '',
		price: 0,
		satPaymentMethod: 'PUE',
		partialities: '',
		advanceAmount: 0,
		paymentDate: '',
		cie: '',
		reference: '',
		area: '',
		from_loan: false,
		branch_office_id: '',
	},
	order: {
		quantity: 1,
		measurement_unit: '',
		sku: '',
		description: '',
		unit_cost: 0,
		link: '',
		service_order: '',
	},
}
